import PropTypes from 'prop-types';
import { Modal } from 'bv-components';
import { t } from 'bv-i18n';

const ConfirmationModal = ({ handleNoBtnClick, handleYesBtnClick }) => (
  <Modal
    success
    icon
    title={t('time_out.confirmation_modal.are_you_sure?')}
    actions={[
      {
        primary: true,
        inverse: true,
        label: t('time_out.confirmation_modal.false'),
        onClick: handleNoBtnClick,
      },
      {
        primary: true,
        label: t('time_out.confirmation_modal.true'),
        onClick: handleYesBtnClick,
      },
    ]}
  >
    <p>{t('time_out.confirmation_modal.notice')}</p>
  </Modal>
);

ConfirmationModal.propTypes = {
  handleNoBtnClick: PropTypes.func.isRequired,
  handleYesBtnClick: PropTypes.func.isRequired,
};

export default ConfirmationModal;
