import PropTypes from 'prop-types';
import { SliderSelector } from 'bv-components';

const Period = ({ input: { onChange, value }, timeOutPeriods }) => (
  <div className="time-out-form__row">
    <SliderSelector
      selections={timeOutPeriods}
      selectedId={value}
      name="period-selector"
      onChange={({ target: { value: val } }) => onChange(Number(val) || val)}
      triggerWhileScrolling={false}
      isWiderItem
    />
  </div>
);

Period.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
  timeOutPeriods: PropTypes.arrayOf(Object).isRequired,
};

export default Period;
