import PropTypes from 'prop-types';
import { Modal } from 'bv-components';
import { t } from 'bv-i18n';

const SuccessSubmitModal = ({ closeModal }) => (
  <Modal
    success
    icon
    title={t('time_out.success_submit_modal.request_was_successful')}
    iconClosable
    onCloseClick={closeModal}
  />
);

SuccessSubmitModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default SuccessSubmitModal;
