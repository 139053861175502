import PropTypes from 'prop-types';
import { t } from 'bv-i18n';

const TimeOutStatus = ({ periodId, isActive }) => (
  <>
    <span className="bvs-header">
      {t('time_out.time_out_status')}
      {': '}
      {isActive ? t('time_out.status.active') : t('time_out.status.inactive')}
    </span>
    {isActive
      && (
      <span className="bvs-header">
        {t('time_out.time_period_selected')}
        :
        {t(`time_out.periods.ids.${periodId}`)}
      </span>
      )}
  </>
);

TimeOutStatus.propTypes = {
  isActive: PropTypes.bool.isRequired,
  periodId: PropTypes.number,
};

TimeOutStatus.defaultProps = {
  periodId: 0,
};

export default TimeOutStatus;
