import { createSelector } from 'reselect';
import { NONE_SET_SLIDER_ELEMENT } from './helpers';

const getTimeOutPeriods = (state) => state.timeOut.timeOutPeriods;

export const getTimeOutSliderElements = createSelector(
  getTimeOutPeriods, (periods) => {
    if (periods) {
      return [NONE_SET_SLIDER_ELEMENT, ...periods];
    }

    return [];
  },
);
