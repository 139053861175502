import PropTypes from 'prop-types';
import { Modal } from 'bv-components';
import { t } from 'bv-i18n';

const FailureModal = ({ error, closeModal }) => (
  <Modal
    title={t('time_out.error_title')}
    danger
    iconClosable
    icon={false}
    onCloseClick={closeModal}
  >
    <p>{error}</p>
  </Modal>
);

FailureModal.propTypes = {
  error: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default FailureModal;
