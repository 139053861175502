import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'underscore';
import { registerHandler, unregisterHandler } from 'event-bus';
import { accountId } from 'bv-helpers/session';
import { Spinner } from 'bv-components';
import { t } from 'bv-i18n';
import { withScrollToTop } from 'bv-hocs';
import {
  fetchTimeOutPeriods as fetchTimeOutPeriodsAction,
  timeOutUser as timeOutUserAction,
  closeSubmitResultModal as closeSubmitResultModalAction,
  closeSucceededModal as closeSucceededModalAction,
  fetchUserIsOnTimeOut as fetchUserIsOnTimeOutAction,
  clearTimeOut as clearTimeOutAction,
} from '../duck';
import { getTimeOutSliderElements } from '../selectors';

import Form from './form';
import Summary from './summary';
import SuccessSubmitModal from './success_submit_modal';
import FailureModal from './failure_modal';
import TimeOutStatus from './time_out_status';

const TimeOutApp = ({
  timeOutPeriodsLoaded,
  timeOutPeriodsError,
  timeOutPeriods,
  timeOutStatusLoaded,
  timeOutStatusError,
  timeOutUser,
  userIsOnTimeOut,
  activePeriodId,
  userTimeOutSucceeded,
  userTimeOutFailed,
  error,
  footer,
  closeSucceededModal,
  closeSubmitResultModal,
  fetchTimeOutPeriods,
  fetchUserIsOnTimeOut,
  clearTimeOut,
}) => {
  useEffect(() => {
    fetchTimeOutPeriods();
    fetchUserIsOnTimeOut();
    const onMessage = (_, message) => {
      const { body: { status } } = message;
      if (status === 'CONFIRMED') {
        clearTimeOut();
      }
    };

    registerHandler(`/user/${accountId()}/time-out`, onMessage);

    return () => { unregisterHandler(`/user/${accountId()}/time-out`, onMessage); };
  }, []);

  const handleSubmit = ({ period, password }) => timeOutUser({ period, password });

  if (timeOutStatusError || timeOutPeriodsError) {
    return <FailureModal error={t('time_out.general_error')} closeModal={closeSubmitResultModal} />;
  }

  if (!timeOutPeriodsLoaded || !timeOutStatusLoaded) return <Spinner />;

  return (
    <>
      <TimeOutStatus periodId={activePeriodId} isActive={userIsOnTimeOut} />
      {!userIsOnTimeOut
        && (
        <Form
          onSubmit={handleSubmit}
          timeOutPeriods={timeOutPeriods}
        />
        )}
      <Summary />

      {footer}
      {userTimeOutSucceeded && (
        <SuccessSubmitModal closeModal={closeSucceededModal} />
      )}
      {userTimeOutFailed && (
        <FailureModal error={error} closeModal={closeSubmitResultModal} />
      )}
    </>
  );
};

TimeOutApp.propTypes = {
  timeOutPeriods: PropTypes.arrayOf(Object).isRequired,
  timeOutPeriodsLoaded: PropTypes.bool.isRequired,
  timeOutPeriodsError: PropTypes.bool,
  fetchTimeOutPeriods: PropTypes.func.isRequired,
  timeOutUser: PropTypes.func.isRequired,
  userIsOnTimeOut: PropTypes.bool.isRequired,
  timeOutStatusLoaded: PropTypes.bool.isRequired,
  timeOutStatusError: PropTypes.bool,
  activePeriodId: PropTypes.number.isRequired,
  userTimeOutSucceeded: PropTypes.bool.isRequired,
  userTimeOutFailed: PropTypes.bool.isRequired,
  error: PropTypes.string,
  closeSubmitResultModal: PropTypes.func.isRequired,
  closeSucceededModal: PropTypes.func.isRequired,
  footer: PropTypes.element,
  fetchUserIsOnTimeOut: PropTypes.func.isRequired,
  clearTimeOut: PropTypes.func.isRequired,
};

TimeOutApp.defaultProps = {
  footer: null,
  error: null,
  timeOutPeriodsError: false,
  timeOutStatusError: false,
};

const mapStateToProps = (state) => {
  const {
    timeOut: {
      timeOutPeriodsLoaded,
      timeOutStatusLoaded,
      timeOutPeriodsError,
      timeOutStatusError,
      userIsOnTimeOut,
      activePeriodId,
      userTimeOutSucceeded,
      userTimeOutFailed,
      error,
    },
  } = state;

  return {
    timeOutPeriods: getTimeOutSliderElements(state),
    timeOutPeriodsLoaded,
    timeOutStatusLoaded,
    timeOutPeriodsError,
    timeOutStatusError,
    userIsOnTimeOut,
    activePeriodId,
    userTimeOutSucceeded,
    userTimeOutFailed,
    error,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchUserIsOnTimeOut: compose(dispatch, fetchUserIsOnTimeOutAction),
  clearTimeOut: compose(dispatch, clearTimeOutAction),
  fetchTimeOutPeriods: compose(dispatch, fetchTimeOutPeriodsAction),
  timeOutUser: compose(dispatch, timeOutUserAction),
  closeSubmitResultModal: compose(dispatch, closeSubmitResultModalAction),
  closeSucceededModal: compose(dispatch, closeSucceededModalAction),
});

export default withScrollToTop()(connect(mapStateToProps, mapDispatchToProps)(TimeOutApp));
