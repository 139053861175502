import { useState } from 'react';
import PropTypes from 'prop-types';
import { Form as ReactFinalForm, Field } from 'react-final-form';
import { Card, Button } from 'bv-components';
import { useLoggedIn } from 'bv-hooks';
import { v as bvVar } from 'bv';
import { t } from 'bv-i18n';

import PasswordWithToggle from 'SharedComponents/password/components/password';
import Period from './fields/period';
import { periodIsNoneSet, getSelectedPeriodTitle } from '../helpers';
import ConfirmationModal from './confirmation_modal';

const requiredValidation = (value) => (value ? undefined : t('javascript.required_field'));

const Form = ({
  timeOutPeriods,
  onSubmit,
}) => {
  const loggedIn = useLoggedIn();
  const [confirmationModalOpened, showConfirmationModal] = useState(false);

  return (
    <Card>
      <ReactFinalForm
        onSubmit={onSubmit}
        initialValues={{
          period: timeOutPeriods[0]?.id,
        }}
      >
        {({
          handleSubmit, invalid, submitting, values: { period },
        }) => {
          const noPeriodSet = periodIsNoneSet(period);
          const buttonTitle = t('time_out.time_out_for', {
            value: noPeriodSet ? '...' : getSelectedPeriodTitle(period, timeOutPeriods),
          });

          return (
            <form className="time-out-form">
              <Field
                name="period"
                component={Period}
                timeOutPeriods={timeOutPeriods}
                validate={requiredValidation}
              />
              {loggedIn && !bvVar('skipIdentityConfirmationInSgPortal') && (
              <div className="time-out-form__row">
                <Field
                  name="password"
                  formName="timeOut"
                  component={PasswordWithToggle}
                  validate={requiredValidation}
                  withToggle
                  placeholder={t('time_out.enter_password_to_confirm')}
                  showError={({ touched, error }) => touched && error}
                />
              </div>
              )}
              <div className="time-out-form__row">
                <Button
                  primary
                  type="submit"
                  label={buttonTitle}
                  className="time-out-form__submit"
                  disabled={invalid || submitting || noPeriodSet}
                  onClick={(e) => {
                    e.preventDefault();
                    showConfirmationModal(true);
                  }}
                />
              </div>
              {confirmationModalOpened && (
                <ConfirmationModal
                  handleNoBtnClick={() => showConfirmationModal(false)}
                  handleYesBtnClick={() => {
                    showConfirmationModal(false);
                    handleSubmit();
                  }}
                />
              )}
            </form>
          );
        }}
      </ReactFinalForm>
    </Card>
  );
};

Form.propTypes = {
  timeOutPeriods: PropTypes.arrayOf(Object).isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default Form;
