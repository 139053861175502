import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'bv-components';
import classnames from 'classnames';

const Password = ({
  input, placeholder, meta, formName, showError, withToggle, ...rest
}) => {
  const [showCharInput, setShowCharInput] = useState(false);
  const inputRef = useRef(null);

  const inputClassNames = classnames(
    'bvs-form-control', {
      error: meta.error && meta.touched,
      active: meta.active,
      'with-value': meta.dirty,
    },
  );

  const handleEyeIconClick = () => {
    setShowCharInput(!showCharInput);
    setTimeout(() => inputRef.current.focus(), 100);
  };

  return (
    <div className="bvs-form-group">
      <input
        {...input}
        ref={inputRef}
        name={input.name}
        id={input.name}
        type={showCharInput ? 'text' : 'password'}
        className={inputClassNames}
        autoCapitalize="off"
        autoCorrect="off"
        autoComplete="off"
        {...rest}
      />
      <label className="bvs-form-control-placeholder" htmlFor={input.name}>{placeholder}</label>
      {withToggle && (
        <Icon
          id={`eye-catcher-${showCharInput ? 'active' : 'inactive'}`}
          onClick={handleEyeIconClick}
          className="account-form__eye-catcher-icon"
        />
      )}
      {showError(meta) && <span className="bvs-form-error-msg">{meta.error || meta.submitError}</span>}
    </div>
  );
};

Password.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
  meta: PropTypes.instanceOf(Object).isRequired,
  formName: PropTypes.string.isRequired,
  showError: PropTypes.func.isRequired,
  withToggle: PropTypes.bool.isRequired,
  placeholder: PropTypes.string.isRequired,
};

export default Password;
