import App from './components/app';
import reducer from './duck';

const { addReducers } = window.reduxState;

addReducers({
  timeOut: reducer,
});

const TimeOut = (props) => (
  <App {...props} />
);

export default TimeOut;
