import { Card, Icon } from 'bv-components';
import { hasSportsbook, hasCasino } from 'global-config';
import { t } from 'bv-i18n';

const features = [
  {
    key: 'not_available',
    icon: 'close',
    prefix: 'not_',
    items: [
      ...(hasSportsbook ? [1, 4] : []),
      ...(hasCasino ? [2] : []),
      3,
    ].sort((a, b) => a - b),
  },
  {
    key: 'available',
    icon: 'check',
    prefix: '',
    items: [1, 2, 3, 4],
  },
];

const TimeOutSummary = () => (
  <>
    <span className="bvs-header">
      {t('time_out.feature_restrictions.title')}
    </span>
    <Card>
      <div className="time-out-portal">
        {features.map(({
          key, icon, prefix, items,
        }) => (
          <div key={key} className="time-out-portal__features">
            <div><Icon id={icon} type="time-out-portal-icon" /></div>
            <ul>
              {items.map((item) => (
                <li key={item}>{t(`time_out.feature_restrictions.${prefix}available.${item}`)}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </Card>
  </>
);

export default TimeOutSummary;
